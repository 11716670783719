/* Mutations, actions and getters */
import mutations from './mutations';
import actions from './actions';
import getters from './getters';

export default {
  namespaced: true,
  state: {
    create_api: null,
    read_api: null,
    pdf_id: null,
    pdf_file: null,
    reference_width: null,
    reference_height: null,
    reference_sign_width: null,
    reference_sign_height: null,
    images: [],
    currentPage: null,
    signatures: [],
    variables_configuration: []
  },
  actions,
  mutations,
  getters
};
