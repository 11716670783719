import * as types from "../mutation_types";
import axios from "@helpers/axios";

// https://run.mocky.io/v3/ee11687f-e1b7-45f4-b6f7-ed6012541a9b --> totally not completed mocky
// https://run.mocky.io/v3/e9c0a6c7-a2a4-4b48-b4e4-e3dd1466c375- --> parzially not completed
// https://run.mocky.io/v3/8e4d3555-b2ad-4b34-95ea-0d29a4c2b135 -->completed mocky
export default {
  async setQuizInitData({ commit, state }) {
    try {
      if(state.quizConf === null){
        const response = await axios.get("/api/v1/quiz/")
        commit(types.SET_QUIZ_INIT_DATA, response.data);
      } else {
        return Promise.resolve();
      }

    } catch (e) {
      let properties = Object.getOwnPropertyNames(e);
      properties.forEach((p) => {
        let prop = Object.getOwnPropertyDescriptor(e, p);
        if (prop.value.status) {
          commit(types.SET_ERRORS, prop.value.status);
          //console.log(prop.value.status)
        }
      });
    }
  },
  saveResponse({commit}, data){
    commit(types.SAVE_RESPONSE, data)
  },
  isInEditMode({commit}, data){
    commit(types.IS_IN_EDIT_MODE, data)
  }
};
