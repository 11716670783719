import * as types from '../mutation_types';

export default {
  [types.SET_QUIZ_INIT_DATA](state, payload) {
    state.quizConf = payload
  },
  [types.SET_ERRORS](state, payload) {
    state.errors.push(payload)
  },
  [types.SAVE_RESPONSE](state, payload) {
    state.quizConf.questions[payload.index].response = payload.response
  },
  [types.IS_IN_EDIT_MODE](state, payload) {
    state.editMode = payload
  },
};
