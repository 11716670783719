import * as types from '../mutation_types';
import axios from 'axios';
import jsonConfigurationFixture from '@fixtures/signatures-fixture';

const debug = false;

export default {
  createContextData({commit}, payload) {
    return new Promise((resolve, reject) => {
      if (debug) {
        payload.pdf_id = jsonConfigurationFixture.pdf_id;
        payload.pdf_file = jsonConfigurationFixture.pdf_file;
      }

      try {
        commit(types.CREATE_CONTEXT_DATA, payload);
        resolve();
      } catch (e) {
        console.log(e);
        reject(e);
      }
    });
  },
  createPdfConfig({commit, state, dispatch}, payload) {
    return new Promise((resolve, reject) => {
      dispatch('setLoading', true, {root: true});

      let call;
      if (debug) {
        call = import('@fixtures/pdf-config-fixture');
      } else {
        let formData = new FormData();
        formData.append('work_area_width', payload.work_area_width);
        formData.append('work_area_height', payload.work_area_height);
        formData.append('pdf_file', payload.pdf_file);
        call = axios.post(state.create_api,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              'X-CSRFToken': document.querySelector('input[name="csrfmiddlewaretoken"]').value
            }
          }
        );
      }
      call.then(response => {
        commit(types.CREATE_PDF_CONFIG, response.data);
        resolve(response.data);
        dispatch('setLoading', false, {root: true});
      }).catch(e => {
        console.log(e);
        reject(e);
        dispatch('setLoading', false, {root: true});
      });
    });
  },
  readPdfConfig({commit, state, dispatch}, payload) {
    return new Promise((resolve, reject) => {
      dispatch('setLoading', true, {root: true});

      let call;
      if (debug) {
        call = import('@fixtures/pdf-config-fixture');
      } else {
        const api = state.read_api.replace('__ID__', state.pdf_id);
        call = axios.get(`${api}?work_area_width=${payload.work_area_width}&work_area_height=${payload.work_area_height}`);
      }
      call.then(response => {
        commit(types.READ_PDF_CONFIG, response.data);
        resolve(response.data);
        dispatch('setLoading', false, {root: true});
      }).catch(e => {
        console.log(e);
        reject(e);
        dispatch('setLoading', false, {root: true});
      });
    });
  },
  updatePdfConfig({commit}, payload) {
    return new Promise((resolve, reject) => {
      try {
        commit(types.UPDATE_PDF_CONFIG, payload);
        resolve();
      } catch (e) {
        console.log(e);
        reject(e);
      }
    });
  },
  deletePdfConfig({commit}, payload) {
    return new Promise((resolve, reject) => {
      try {
        commit(types.DELETE_PDF_CONFIG, payload);
        resolve();
      } catch (e) {
        console.log(e);
        reject(e);
      }
    });
  },
  updateCurrentPage({commit}, payload) {
    return new Promise((resolve, reject) => {
      try {
        commit(types.UPDATE_CURRENT_PAGE, payload);
        resolve();
      } catch (e) {
        console.log(e);
        reject(e);
      }
    });
  },
  createAllSignature({commit}, payload) {
    return new Promise((resolve, reject) => {
      if (debug) {
        payload.old_work_area_width = jsonConfigurationFixture.work_area_width;
        payload.old_work_area_height = jsonConfigurationFixture.work_area_height;
        payload.signatures = jsonConfigurationFixture.signatures;
      }

      try {
        commit(types.CREATE_ALL_SIGNATURE, payload);
        resolve();
      } catch (e) {
        console.log(e);
        reject(e);
      }
    });
  },
  updateAllSignature({commit}, payload) {
    return new Promise((resolve, reject) => {
      try {
        commit(types.UPDATE_ALL_SIGNATURE, payload);
        resolve();
      } catch (e) {
        console.log(e);
        reject(e);
      }
    });
  },
  createSignature({commit}, payload) {
    return new Promise((resolve, reject) => {
      try {
        commit(types.CREATE_SIGNATURE, payload);
        resolve();
      } catch (e) {
        console.log(e);
        reject(e);
      }
    });
  },
  updateSignature({commit}, payload) {
    return new Promise((resolve, reject) => {
      try {
        commit(types.UPDATE_SIGNATURE, payload);
        resolve();
      } catch (e) {
        console.log(e);
        reject(e);
      }
    });
  },
  deleteSignature({commit}, payload) {
    return new Promise((resolve, reject) => {
      try {
        commit(types.DELETE_SIGNATURE, payload);
        resolve();
      } catch (e) {
        console.log(e);
        reject(e);
      }
    });
  },
  createAllVariable({commit}, payload) {
    return new Promise((resolve, reject) => {
      if (debug) {
        payload.old_work_area_width = jsonConfigurationFixture.work_area_width;
        payload.old_work_area_height = jsonConfigurationFixture.work_area_height;
        payload.variables_configuration = jsonConfigurationFixture.variables_configuration;
      }

      try {
        commit(types.CREATE_ALL_VARIABLE, payload);
        resolve();
      } catch (e) {
        console.log(e);
        reject(e);
      }
    });
  },
  updateAllVariable({commit}, payload) {
    return new Promise((resolve, reject) => {
      try {
        commit(types.UPDATE_ALL_VARIABLE, payload);
        resolve();
      } catch (e) {
        console.log(e);
        reject(e);
      }
    });
  },
  createVariable({commit}, payload) {
    return new Promise((resolve, reject) => {
      try {
        commit(types.CREATE_VARIABLE, payload);
        resolve();
      } catch (e) {
        console.log(e);
        reject(e);
      }
    });
  },
  updateVariable({commit}, payload) {
    return new Promise((resolve, reject) => {
      try {
        commit(types.UPDATE_VARIABLE, payload);
        resolve();
      } catch (e) {
        console.log(e);
        reject(e);
      }
    });
  },
  deleteVariable({commit}, payload) {
    return new Promise((resolve, reject) => {
      try {
        commit(types.DELETE_VARIABLE, payload);
        resolve();
      } catch (e) {
        console.log(e);
        reject(e);
      }
    });
  }
};
