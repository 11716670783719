<template>
  <div class="Home">
    <HeaderCmp />
    <main class="main">
      <router-view :key="$route.path" />
    </main>
    <page-loader-cmp />
    <FooterCmp class="footer" />
    <CommonIcons />
  </div>
</template>

<script>
import HeaderCmp from "@components/header.vue";
import FooterCmp from "@components/footer.vue";
import PageLoaderCmp from "@components/page-loader-cmp";
import CommonIcons from "@components/common-icons";
export default {
  name: "Home",
  components: {
    HeaderCmp,
    FooterCmp,
    PageLoaderCmp,
    CommonIcons,
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
@import "~$scss/variables";

.Home {
  display: flex;
  flex-direction: column;
  height: 100vh;

  .main {
    flex-basis: 100%;
  }
}
</style>
