<template>
  <section class="CommonIcons">
    <!-- Arrow Left -->
    <svg
      id="icon-arrow-left"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 35.71 63.62"
      aria-labelledby="titleArrowLeft contentArrowLeft"
      role="img"
    >
      <title id="titleArrowLeft">Arrow Left</title>
      <desc id="contentArrowLeft">Arrow Left</desc>
      <g>
        <g>
          <path d="M1.14,29,29,1.14a4,4,0,0,1,5.62,0,4,4,0,0,1,0,5.62l-25,25L34.57,56.86A4,4,0,1,1,29,62.48L1.14,34.57A4,4,0,0,1,1.14,29Z" />
        </g>
      </g>
    </svg>
    <!-- Arrow Right -->
    <svg
      id="icon-arrow-right"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 35.71 63.62"
      aria-labelledby="titleArrowRight contentArrowRight"
      role="img"
    >
      <title id="titleArrowRight">Arrow right</title>
      <desc id="contentArrowRight">Arrow right</desc>
      <g>
        <g>
          <path d="M34.57,34.57,6.76,62.48a4,4,0,0,1-5.62,0,4,4,0,0,1,0-5.62l25.05-25-25-25a4,4,0,0,1,0-5.62,4,4,0,0,1,5.62,0L34.57,29A4,4,0,0,1,34.57,34.57Z" />
        </g>
      </g>
    </svg>

    <!-- Check icon -->
    <svg
      id="icon-check"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 58.33 42.05"
      aria-labelledby="titleIconCheck contentIconCheck"
      role="img"
    >
      <title id="titleIconCheck">Icon Check</title>
      <desc id="contentIconCheck">Icon Check</desc>
      <g>
        <g>
          <path d="M54.18,0a4,4,0,0,0-2.75,1.22C41.5,11.17,30.87,22.57,21,32.63L6.8,21a4,4,0,0,0-5.6,5.72,4.51,4.51,0,0,0,.54.44l17,14a4,4,0,0,0,5.37-.25c10.75-10.77,22.42-23.39,33-34A4,4,0,0,0,54.18,0Z" />
        </g>
      </g>
    </svg>

    <!-- Close icon -->
    <svg
      id="icon-close"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 90 90"
      aria-labelledby="titleIconClose contentIconClose"
      role="img"
    >
      <title id="titleIconClose">Icon Close</title>
      <desc id="contentIconClose">Icon Close</desc>
      <g>
        <g>
          <path
            d="M45,0A45,45,0,1,0,90,45,45,45,0,0,0,45,0Z"
            style="fill:#fff"
          />
          <circle
            cx="44.97"
            cy="44.97"
            r="33.17"
            style="fill:#539aea"
          />
          <path
            d="M29.58,25.45a5.48,5.48,0,0,0-3.26,9.41l10.5,10.49-10.5,10.5a5.5,5.5,0,1,0,7.77,7.78h0l10.49-10.5,10.5,10.5a5.49,5.49,0,0,0,8-7.55l-.24-.23-10.5-10.5,10.5-10.49a5.46,5.46,0,1,0-7.72-7.72L44.59,37.63,34.1,27.14a5.52,5.52,0,0,0-4-1.69Z"
            style="fill:#fff"
          />
        </g>
      </g>
    </svg>
  </section>
</template>

<script>
export default {
  name: 'CommonIcons',
};
</script>

<style lang="scss" scoped>
.CommonIcons {
  position: fixed;
  right: -9999px;
  bottom: -9999px;
  width: 0;
  height: 0;
  visibility: hidden;

  svg {
    visibility: visible;
  }
}
</style>
