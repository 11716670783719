export default {
  pdf_id: 1,
  pdf_file: '',
  signatures: [
    {
      id: 1,
      page_number: 1,
      signature_position_x: 13,
      signature_position_y: 75,
      signature_width: 240,
      signature_height: 60
    },
    {
      id: 2,
      page_number: 2,
      signature_position_x: 13,
      signature_position_y: 75,
      signature_width: 240,
      signature_height: 60
    }
  ],
  work_area_width: 595,
  work_area_height: 842
};
