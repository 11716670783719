/* Router dependencies */
import Vue from "vue";
import VueRouter from "vue-router";

//import Componenti
import Quiz from './pages/quiz.vue'
import Products from './pages/products.vue'
import Product from './pages/product.vue'
import CampaignClosed from './pages/campaign-closed.vue'
import Unauthorized from './pages/unauthorized.vue'

import axios from '../../helpers/axios'

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "quiz",
    component: Quiz,
  },
  {
    path: "/products",
    name: "products",
    component: Products,
  },
  {
    path:'/products/:slug/:response?',
    name: 'product',
    props: true,
    component: Product,
  },
  {
    path: "/campaign-closed",
    name: "campaign-closed",
    component: CampaignClosed,
  },
  {
    path: "/unauthorized",
    name: "unauthorized",
    component: Unauthorized,
  },
  {
    path: '*',
    redirect: { name: 'quiz'}
  }
];

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

const initApp = async (to, from, next) => {
  if (!axios.defaults.headers.common.Authorization && to.query.code) {
    axios.defaults.headers.common.Authorization = `Token ${to.query.code}`;
  }
  if (from.query.code && !to.query.code) {
    next({ name: to.name, params: to.params, query: { ...to.query, code: from.query.code } });
    return;
  }
  router.app.$store.dispatch('setLoading', true);
  try {
    if (router.app.$store.state.appReady) {
      // App is ready, API already called
      next();
    } else {
      // App not ready, call API
      // Here you can call some api of initialization
      // Here you can set some data to store
      // Set appReady to true
      await router.app.$store.dispatch("setAppReady", true);
      await router.app.$store.dispatch("quiz/setQuizInitData").then(() => {
        let error404 = router.app.$store.state.quiz.errors.includes(404);
        let error401 = router.app.$store.state.quiz.errors.includes(401);
        let error500 = router.app.$store.state.quiz.errors.includes(500);
        if (error404) {
          //console.log("errore 404");
          if (to.name === "campaign-closed") {
            next();
          } else {
            // console.log(
            //   "sono in errore 404, vado nella pagina campaign-closed"
            // );
            next({ name: "campaign-closed" });
          }
        } else if (error401 || error500) {
          if (to.name === "unauthorized") {
            next();
          } else {
            //console.log("sono in errore 401, vado nella pagina unauthorized");
            next({ name: "unauthorized" });
          }
        } else if (
          to.name === "campaign-closed" ||
          to.name === "unauthorized"
        ) {
          // console.log(
          //   "sono in campaign-closed o in unauthorized ma non ci sono errori allora vado nella pagina quiz"
          // );
          next({ name: "quiz" });
        } else {
          let sessionStatus = router.app.$store.state.quiz.quizConf.is_completed;
          if (to.name === "quiz") {
            if (!sessionStatus) {
              //console.log("questionario non completato vado nella pagina QUIZ");
              next();
            } else {
              // console.log(
              //   "[questionario completato vado nella pagina PRODUCTS]"
              // );
              next({ name: "products" , query:{ code: to.query.code }});
            }
          } else {
            if (sessionStatus) {
              next();
            } else {
              next({ name: "quiz" });
            }
          }
        }
      });
    }
  } catch (e) {
    console.log(e);
    // Set appReady to true
    await router.app.$store.dispatch("setAppReady", true);

    // API error, redirect to homepage
    next({ name: "quiz" });

  }
};

router.beforeEach(async (to, from, next) => {
  // Controllo bootstrapper
  if (!router.app.$store.state.vueBootstrapper.ready) {
    // Bootstrapper non completo mi metto in watch
    router.app.$store.watch(
      () => router.app.$store.state.vueBootstrapper.ready,
      (status) => {
        if (status) {
          // Bootstrapper completo, procedo
          initApp(to, from, next);
        }
      }
    );
  } else {
    // Bootstrapper completo, procedo
    initApp(to, from, next);
  }
});

export default router;
