import Vue from 'vue';
import VueBootstrapper from 'vue-bootstrapper';
import VueViewportManager from 'vue-viewport-manager';
import store from '@store';
import Home from '@views/home/home';
import router from '@views/home/router';
import CmsSupport from '@mixins/cms-support';
import Vuelidate from 'vuelidate';
Vue.use(Vuelidate);
Vue.use(VueBootstrapper, {store});
Vue.use(VueViewportManager, {store});
Vue.mixin(CmsSupport);

/* eslint-disable */
if (process.env && process.env.VUE_APP_ENV === 'development') {
  console.log('%c[VUE_APP_ENV DEVELOPMENT]', 'padding:4px;background-color:orange;color:white;');
  console.log('%c[ADDED VUE TO WINDOW]', 'padding:4px;background-color:orange;color:white;');
  console.log('%c[VUE DEVTOOLS ENABLED]', 'padding:4px;background-color:orange;color:white;');
  window.Vue = Vue;
  Vue.config.devtools = true;
}
/* eslint-enable */

new Vue({
  el: '#app-root',
  store: store,
  router,
  components: {Home},
  template: `
    <Home />`
});
