<template>
  <div class="Products">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div v-if="products">
            <div v-if="products.length === 0">
              <title-text-box
                v-if="$context.siteCode === $context.solInsuranceSiteCode"
                title="Ci dispiace ma in base al questionario compilato non abbiamo servizi assicurativi adatti a te."
              />
              <title-text-box
                v-else-if="
                  $context.siteCode === $context.insuranceRentingSiteCode
                "
                title="In base alle risposte da te fornite non abbiamo servizi da proporre adatti a te, puoi ripetere il questionario o lasciare la pagina.
                  Grazie per aver completato il questionario."
              />
            </div>
            <button
              type="button"
              class="btn"
              @click.prevent="goBackToQuiz"
            >
              Ripeti questionario
            </button>
          </div>

          <products-list>
            <template slot="product">
              <product-list-item
                v-for="(product, index) in products"
                :key="index"
                :product="product"
                :data-force-disabled="
                  product.activated ? false : thereIsAnActivatedProduct
                "
                @select-product="activateProduct(product.slug)"
              />
            </template>
          </products-list>
          <div v-if="errors.length">
            <h2>C'è stato un errore</h2>
            <button
              type="button"
              class="btn"
              @click.prevent="initProducts"
            >
              Riprova
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import TitleTextBox from '@components/title-text-box';
import ProductsList from '../../../components/products-list.vue';
import ProductListItem from '../../../components/product-list-item.vue';
export default {
  name: 'Products',
  components: { TitleTextBox, ProductsList, ProductListItem },
  data() {
    return {
      fromEsign: null,
    };
  },
  computed: {
    ...mapState('products', ['products', 'errors', 'product']),
    ...mapState('quiz', ['quizConf']),
    title() {
      return this.quizConf.title;
    },
    description() {
      return this.quizConf.text;
    },
    thereIsAnActivatedProduct() {
      return !!this.products.find((p) => p.activated);
    },
  },
  mounted() {
    this.$store.commit('products/RESET_PRODUCT_DETAIL', null);
    this.$store.commit('products/RESET_PRODUCTS', null);
    this.fromEsign = this.$route.query.from_esign;
    if (this.fromEsign) {
      setTimeout(() => {
        this.initProducts();
      }, 10000); // 10 secondi di timeout
    } else {
      this.initProducts();
    }
  },
  methods: {
    initProducts() {
      this.$store.dispatch('products/setProductsInitData').then(() => {
        this.$store.dispatch('setLoading', false);
      });
    },
    goBackToQuiz() {
      this.$store.dispatch('quiz/isInEditMode', true);
      this.$store.commit('products/RESET_PRODUCTS', null);
      this.$router.push({
        name: 'quiz',
        query: { code: this.$route.query.code },
      });
    },
    activateProduct(slug) {
      this.$store.dispatch('products/setProductDetail', slug).then(() => {
        this.$store.dispatch('setLoading', false);
        if (this.product.sign_link) {
          window.location.replace(this.product.sign_link);
        } else {
          this.$router
            .push({
              name: 'product',
              params: { slug: slug },
            })
            .catch(() => {});
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~$scss/_variables';
@import '~bootstrap/scss/mixins/breakpoints';

.Products {
  .btn {
    border: 0;
    border-radius: 4px;
    padding: 15px 32px;
    margin-top: 95px;
    cursor: pointer;
    color: #fff;
    font-size: 19px;
    width: 100%;
    background: #eb1274;

    @include media-breakpoint-up(md) {
      width: auto;
    }

    &:hover {
      background: #e10568;
    }

    &:focus {
      outline: 0;
    }
  }

  .noProductsText {
    text-align: center;
    padding: 20px;
  }
}
</style>
