<template>
  <div class="QuestionCarouselItem">
    <h3 class="title">
      {{ conf.title }}
    </h3>
    <div class="text">
      <div v-html="conf.text" />
    </div>
    <div class="buttons">
      <button
        :class="['btn btnConfirm', btnYesStyleColor]"
        @click.prevent="handleConfirm"
      >
        {{ conf.ctaConfirm }}
      </button>
      <button
        :class="['btn btnNotConfirm', btnNoStyleColor]"
        @click.prevent="handleNotConfirm"
      >
        {{ conf.ctaNotConfirm }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "QuestionCarouselItem",
  props: {
    conf: {
      type: Object,
      required: true,
    },
    btnColor:{
      type:String,
      required:false,
      default:''
    },
    btnColorYes:{
       type:String,
      required:false,
      default:''
    },
    btnColorNo:{
       type:String,
      required:false,
      default:''
    }
  },
  computed:{
    btnYesStyleColor(){
      return this.btnColorYes === 'green' ? 'green-btn' : 'grey-btn'
    },
    btnNoStyleColor(){
      return this.btnColorNo === 'red' ? 'red-btn' : 'grey-btn'
    }
  },
  methods: {
    handleConfirm() {
      this.$emit("handle-confirm");
    },
    handleNotConfirm() {
      this.$emit("handle-not-confirm");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~$scss/_variables";
@import "~bootstrap/scss/mixins/breakpoints";

.QuestionCarouselItem {
  .title {
    font-size: 32px;
    line-height: 40px;
    font-weight: $fh-normal;
    color: $color-light-blue;
    @include media-breakpoint-up(md){
      font-size: 45px;
    }
  }

  .text {
    padding: 20px 0;
    font-size: 18px;
    line-height: 24px;
    font-weight: $fh-thin;
    margin-bottom: 0;
    color: $color-light-blue;
    @media (min-width: $bootstrap-md) {
      width: 80%;
      padding-bottom: 40px;
    }
  }

  .buttons {
    display: flex;
    justify-content: space-between;

    @include media-breakpoint-up(md) {
      justify-content: flex-start;
    }

    .btn {
      border: 0;
      border-radius: 4px;
      padding: 15px 32px;
      cursor: pointer;
      color: #fff;
      width: 50%;
      line-height: 30px;
      font-size: 19px;

      @include media-breakpoint-up(md) {
        width: 100px;
        font-size: 22px;
      }

      &:focus {
        outline: 0;
      }

      &.red-btn{
        background: #eb1274;
      }

      &.grey-btn{
         background: $color-grey-disabled;
      }

      &.green-btn{
        background: #80c73d;
      }
      &.btnConfirm {
        transition: all 0.2s linear;
        
        margin-right: 20px;

        @include media-breakpoint-up(md) {
          margin-bottom: 0;
        }

        &:hover {
          background: #6fa43a;
        }

        &:disabled {
          background: $color-grey-disabled;
        }
      }

      &.btnNotConfirm {
        transition: all 0.2s linear;
        

        &:hover {
          background: #e10568;
        }

        &:disabled {
          background: $color-grey-disabled;
        }
      }
    }
  }
}
</style>
