<template>
  <transition name="fade">
    <div
      v-if="loading"
      class="PageLoaderCmp"
    >
      <div class="loader">
        <svg class="circular">
          <circle
            class="path"
            cx="25"
            cy="25"
            r="20"
            fill="none"
            stroke-width="2"
            stroke-miterlimit="10"
          />
        </svg>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'PageLoaderCmp',
  computed: {
    loading() {
      return this.$store.state.loading;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~$scss/variables";

.PageLoaderCmp {
  position: fixed;
  top: 0;
  left: 0;
  z-index: map_get($z, top);
  background: rgba($color-white, .97);
  width: 100%;
  height: 100%;

  .loader {
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translateX(-100%) translateY(-50%);
    transform: translateY(-50%);
    margin-left: -25px;
  }
}

.loader {
  display: inline-block;
  vertical-align: top;
  will-change: transform;

  .circular {
    animation: rotate 2s linear infinite;
    height: 50px;
    position: relative;
    width: 50px;
  }

  .path {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    animation: dash 1.5s ease-in-out infinite,
    color 6s ease-in-out infinite;
    stroke-linecap: round;
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes dash {
    0% {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -124;
    }
  }
  @keyframes color {
    100%, 0% {
      stroke: $color-primary;
    }
    40% {
      stroke: $color-primary;
    }
    66% {
      stroke: $color-primary;
    }
    80%, 90% {
      stroke: $color-primary;
    }
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
