<template>
  <footer class="FooterCmp">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <ul
            v-if="links"
            class="listItems"
          >
            <li
              v-for="link in links"
              :key="link.slug"
              class="listItem"
            >
              <a
                :href="link.url"
                target="_blank"
                class="link"
              >{{ link.label }}</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <template
            v-if="$context.siteCode === $context.solInsuranceSiteCode"
          >
            <p class="legalText">
              Dati dell'intermediario:
              Ragione sociale: Stellantis Financial Services Italia S.p.A. ,
              Sede legale e operativa: Via Plava, 80 - 10135 Torino (TO)
              Sito Internet: <a
                href="https://www.stellantis-financial-services.it"
                target="_blank"
              >www.stellantis-financial-services.it</a>
              E-mail - PEC <a href="mailto:pec@stellantis-financial-services.cert.it">pec@stellantis-financial-services.cert.it</a>
            </p>
            <p class="legalText small">
              L'autorità competente alla vigilanza sull’attività svolta sul territorio della Repubblica italiana è l’IVASS.
              Gli estremi identificativi e di iscrizione di Stellantis Financial Services Italia S.p.A. possono essere verificati consultando il Registro Unico degli Intermediari assicurativi sul sito internet dell’IVASS (<a
                href="https://www.ivass.it"
                target="_blank"
              >www.ivass.it</a>).
            </p>
          </template>
          <template
            v-else-if="$context.siteCode === $context.insuranceRentingSiteCode"
          >
            <p class="legalText">
              Stellantis Renting S.p.A. società di diritto italiano con sede legale a Trento in Via dei Solteri 105.
            </p>
            <p class="legalText small">
              <a
                :href="$context.privacyPolicyDocumentUrl"
                target="_blank"
              >Clicca qui per leggere l'informativa privacy del sito</a>
            </p>
          </template>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "FooterCmp",
  props: {
    links: {
      type: Array,
      required: false,
      default:()=>[]
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
@import "~$scss/_variables";
@import "~bootstrap/scss/mixins/breakpoints";

.FooterCmp {
  background-color: #e6e6e6;
  width: 100%;
  margin-top: 80px;
  padding: 20px;

  @include media-breakpoint-up(md) {
    padding: 30px 60px;
  }
  .legalText {
    font-size: 11px;
    margin: 0;
    a{
      text-decoration: underline;
      color:$color-black;
    }
    &.small{
      font-size: 9px;
      margin: 5px 0 0;
    }
  }
  .listItems {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    padding: 0;

    .listItem {
      list-style: none;
      margin: 0;

      &::after {
        content: "/";
        display: inline-block;
        position: static;
        margin: 0 10px;
      }

      &:last-child {
        &::after {
          display: none;
        }
      }

      .link {
        color: $color-black;
      }
    }
  }
}
</style>
