<template>
  <div class="QuestionsCarousel section-half-padding-top">
    <div
      ref="$scroller"
      class="swiper-container"
    >
      <div class="swiper-wrapper">
        <div
          v-for="(item, index) in questions"
          :key="index"
          class="swiper-slide"
        >
          <question-carousel-item
            :conf="{
              title: `Domanda ${item.order}`,
              text: item.label,
              ctaConfirm: ctaLabelYes,
              ctaNotConfirm: ctaLabelNo,
            }"
            :btn-color-no="item.response === '' || item.response === 'yes' ? '' : activeColorNo"
            :btn-color-yes="item.response === '' || item.response === 'yes' ? activeColorYes : ''"
            @handle-confirm="submitResponse('yes', index)"
            @handle-not-confirm="submitResponse('no', index)"
          />
        </div>
      </div>
    </div>
    <div class="navigationWrapper">
      <button
        :disabled="current === 0"
        type="button"
        class="swiper-button swiper-button-prev"
        @click.prevent="goToPrev()"
      >
        <svg
          width="20"
          height="20"
        >
          <use
            href="#icon-arrow-left"
            fill="currentColor"
          />
        </svg>
      </button>
      <button
        v-if="nextArrowIsActive && current !== questions.length -1"
        type="button"
        class="swiper-button swiper-button-next"
        @click.prevent="goToNext(current)"
      >
        <svg
          width="18"
          height="18"
        >
          <use
            href="#icon-arrow-right"
            fill="currentColor"
          />
        </svg>
      </button>
      <button
        v-if="editMode && current === questions.length -1"
        type="button"
        class="swiper-button swiper-button-next"
        @click.prevent="goToProductsPage"
      >
        {{ ctaGoToProducts }}
      </button>
    </div>
  </div>
</template>

<script>
//REQUISITI
/**
 * All’atterraggio si deve posizionare sulla prima domanda a cui l’utente non ha ancora risposto, di default la prima --> OK
 * Al click su “Si” o “No” va fatta una POST su /api/v1/quiz/:id/ con la risposta, e se si riceve dal server una risposta con codice 200 si va avanti alla slide successiva --> OK
 * Disabilitare lo swipe, si potrà navigare solo rispondendo alla domanda o con le frecce di navigazione --> OK
 * La freccia per tornare alla domanda precedente si attiva dalla seconda domanda in poi --> OK
 * La freccia per andare alla domanda successiva è attiva solo se alla domanda successiva si ha già risposto o se comunque la domanda successiva si è raggiunta anche se non risposta --> OK
 * Quando si risponde all’ultima domanda si naviga verso /products/  (tranne se ci si trova in modalità di modifica delle domande, perchè ci sarà l’apposito link)
 */

import Swiper from "swiper";
import QuestionCarouselItem from "@components/question-carousel-item";
import { mapGetters, mapState } from "vuex";
import axios from '@helpers/axios';
export default {
  name: "QuestionsCarousel",
  components: { QuestionCarouselItem},
  props: {
    ctaLabelYes: {
      type: String,
      required: true,
    },
    ctaLabelNo: {
      type: String,
      required: true,
    },
    ctaGoToProducts: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      swiper: null,
      disabledYes: null,
      disabledNo: null,
      hasError: false,
      nextArrowIsActive: null,
      current: null,
      activeColorYes: 'green',
      activeColorNo: 'red',
    };
  },
  computed: {
    ...mapState("quiz", ["editMode"]),
    ...mapGetters("quiz", ["questions"]),
    firstQuestionToShow() {
      //trovo primo elemento che non ha risposta e lo passo come prima slide da mostrare
      return this.questions && this.questions.findIndex(q => q.response === "" || q.response === null)
    },
  },
  mounted() {

    this.$nextTick(() => {
       if (this.editMode) {
        this.nextArrowIsActive = true;
        this.current = this.questions.length -1
      } else {
        this.current = this.firstQuestionToShow
      }
      this.swiper = new Swiper(this.$refs.$scroller, {
        slidesPerView: 1,
        spaceBetween: 100,
        speed: 600,
        allowTouchMove: false, //use  slidePrev or slideNext methods
        centeredSlides: false,
        loop: false,
        initialSlide: this.current
      });

    });

  },
  beforeDestroy() {
    if (this.swiper) {
      this.swiper.destroy();
      this.swiper = null;
    }
  },
  methods: {
    goToProductsPage() {
      this.$store.dispatch("quiz/isInEditMode", true);
      this.$router.push({ name: 'products' }).catch(() => {});
    },
    async submitResponse(response, i) {
      try {
        ///api/v1/quiz/${this.questions[i].id}
        await axios.put(
          `api/v1/quiz/${this.questions[i].id}/`, { answer: response }
        );

        this.$store.dispatch("quiz/saveResponse", {
          index: i,
          response: response,
        });
        if (!this.editMode && !this.nextArrowIsActive) {
          this.current++;
          this.swiper.slideNext();

        }
        //se sono all'ultima domanda e ho risposto vado alla pagina prodotti --> this.questions[i].response
        if (i === this.questions.length - 1 && !this.editMode) {
          this.$router.push({ name: "products" }).catch(() => {});
        }

      } catch (e) {
        console.log(e);
        let properties = Object.getOwnPropertyNames(e);
        properties.forEach((p) => {
          let prop = Object.getOwnPropertyDescriptor(e, p);
          if (prop.value.status === 404) {
            console.log("ERRORE");
            this.hasError = true;
          }
        });
      }
    },
    goToPrev() {
      this.nextArrowIsActive = true;
      this.current--;
      this.swiper.slidePrev();
    },
    goToNext(i) {
      this.current++;
      if (this.questions[i + 1].response) {
        this.nextArrowIsActive = true;
      } else {
        this.nextArrowIsActive = false
      }

      if(i === this.questions.length - 1 && this.editMode){
        this.$store.dispatch("quiz/isInEditMode", false);
        this.$router.push({ name: 'products' }).catch(() => {});
        } else {
        this.swiper.slideNext();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~$scss/_variables";
@import "~bootstrap/scss/mixins/breakpoints";

.QuestionsCarousel {
  position: relative;
  .swiper-slide {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .navigationWrapper {
    padding-top: 40px;
    text-align: center;

    @include media-breakpoint-up(md){
      padding-top: 0px;
      position: absolute;
      bottom: 0;
      right: 0;
      z-index: 999999999999;
    }

    .swiper-button {
      border: 0;
      padding: 19px;
      border-radius: 4px;
      background: $color-blue;
      color: #fff;
      cursor: pointer;
      line-height: 22px;
      transition: all .2s;

      &:hover {
        background: $color-blue-hover;
      }

      &:disabled {
        background: #d0d2d6;
      }

      &.swiper-button-next {
        margin-left: 10px;
      }
    }
  }
}
</style>
