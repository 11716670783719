import * as types from "../mutation_types";
import axios from "@helpers/axios";
// 672d24ed7cab917f2174bc201aaa6f597279f6d2
// https://run.mocky.io/v3/75707ddb-0f95-46ae-8884-e02b236585fb --> products
// https://run.mocky.io/v3/bc2e3845-5d71-4888-8458-91f235cbbfd2 --> no products
//https://run.mocky.io/v3/a276d956-64a9-43fe-b316-5f290e0e6fa0 --> product detail
export default {
  async setProductsInitData({ commit }) {
    try {
      const response = await axios.get("/api/v1/products/")
      commit(types.SET_PRODUCTS_INIT_DATA, response.data.products);
    } catch (e) {
      let properties = Object.getOwnPropertyNames(e);
      properties.forEach((p) => {
        let prop = Object.getOwnPropertyDescriptor(e, p);
        if (prop.value.status) {
          commit(types.SET_PRODUCTS_API_ERRORS, prop.value.status);
        }
      });
    }
  },
  async setProductDetail({ commit }, payload) {
    // https://run.mocky.io/v3/2128f70f-a394-4b5d-9ddd-22021a12464a
    // /api/v1/products/:slug/
    try {
      const response = await axios.get(`/api/v1/products/${payload}/`)
      commit(types.SET_PRODUCT_DETAIL, response.data);
    } catch (e) {
      let properties = Object.getOwnPropertyNames(e);
      properties.forEach((p) => {
        let prop = Object.getOwnPropertyDescriptor(e, p);
        if (prop.value.status) {
          commit(types.SET_PRODUCTS_API_ERRORS, prop.value.status);
        }
      });
    }
  }
};

//https://run.mocky.io/v3/2a6dd129-8f73-4b56-a1ff-ec0799a3a6c3 -- post product
