<template>
  <div class="ProductsList">
    <div class="container px-0">
      <div
        v-if="$_cmsSupport_hasContentSlotWithName('product')"
        class="row"
      >
        <slot name="product" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProductsList",
};
</script>

<style lang="scss" scoped>
@import "~$scss/_variables";
@import "~bootstrap/scss/mixins/breakpoints";
.ProductsList{
  margin:80px 0;
}
</style>
