export default {
  title: (state) => {
    return state.quizConf && state.quizConf.title
  },
  text: (state) => {
    return state.quizConf && state.quizConf.text
  },
  questions: (state) => {
    return state.quizConf && state.quizConf.questions;
  },
  sessionStatus:(state)=>{
    return state.quizConf && state.quizConf.is_completed
  }
};
