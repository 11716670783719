<template>
  <div class="TitleTextBox">
    <h2 class="title">
      {{ title }}
    </h2>
    <div v-if="$_cmsSupport_hasContentSlotWithName('content')">
      <slot name="content" />
    </div>
  </div>
</template>

<script>
export default {
  name: "TitleTextBox",
  props: {
    title: {
      type: String,
      required: false,
      default:''
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~$scss/_variables";
@import "~bootstrap/scss/mixins/breakpoints";

.TitleTextBox {
  .title {
    font-size: 32px;
    padding: 40px 0 0 0;

    @include media-breakpoint-up(md) {
      padding: 80px 0 0 0;
      margin: 0;
      font-size: 50px;
    }
  }

  ::v-deep {
    p {
      font-size: 18px;
      line-height: 24px;
      font-weight: $fh-thin;
      margin-top: 20px;

      @include media-breakpoint-up(md) {
        font-size: 20px;
        line-height: 30px;
      }

      strong {
        font-weight: $fh-extra-bold;
      }

      small {
        font-size: 16px;
        line-height: 20px;
        font-weight: $fh-light;

        strong {
          font-weight: $fh-medium;
        }
      }
    }

    ul,
    ol {
      padding-left: 0px;
      li {
        line-height: 24px;
        font-size: 18px;
        font-weight: $fh-thin;
        list-style-position: inside;
        margin-bottom: 5px;
        @include media-breakpoint-up(md) {
          font-size: 20px;
          line-height: 30px;
        }
      }
    }
  }
}
</style>
