<template>
  <div class="CampaignClosed">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <title-text-box title="Link scaduto">
            <template slot="content">
              <p>Ci dispiace ma questa campagna non è più attiva. <br>A presto</p>
            </template>
          </title-text-box>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TitleTextBox from '@components/title-text-box'
export default {
  components: { TitleTextBox },
  data() {
    return {

    }
  },
  mounted(){
    this.$store.dispatch("setLoading", false);
  }
}
</script>

<style>

</style>
