<template>
  <div class="Product">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div v-if="product && !hasError">
            <title-text-box title="Precontrattuale">
              <template slot="content">
                <h2 class="titleProduct">
                  {{ product.name }}
                </h2>
                <div class="description" v-html="product.description" />
              </template>
            </title-text-box>
            <button
              class="btn pdfBtn"
              type="button"
              @click.prevent="downloadPdf(product.precontractual_pdf)"
            >
              Scarica il set informativo
            </button>
            <label class="checkbox">
              <input
                id="acceptRules"
                v-model.trim="$v.checked.$model"
                type="checkbox"
                name="acceptRules"
              />
              <span
                :class="['checkbox-custom', { error: $v.checked.$error }]"
              />
              <span
                class="label"
                for="acceptRules"
                v-html="product.confirm_label"
              />
              <span v-if="$v.checked.$error" class="error">
                Questo campo è obbligatorio
              </span>
            </label>
            <div class="esignLabel" v-html="product.esign_label" />
            <label v-if="product.second_confirm_label" class="checkbox">
              <input
                id="acceptSecondRules"
                v-model.trim="$v.secondChecked.$model"
                type="checkbox"
                name="acceptSecondRules"
              />
              <span
                :class="['checkbox-custom', { error: $v.secondChecked.$error }]"
              />
              <span
                class="label"
                for="acceptSecondRules"
                v-html="product.second_confirm_label"
              />
              <span v-if="$v.secondChecked.$error" class="error">
                Questo campo è obbligatorio
              </span>
            </label>
            <div v-if="product.second_confirm_wysiwyg" class="esignLabel" v-html="product.second_confirm_wysiwyg" />
            <div class="ctaWrapper">
              <button
                type="button"
                class="btn activate"
                :disabled="disabled"
                @click.prevent="activateService"
              >
                Voglio attivare il servizio
              </button>
              <button
                type="button"
                class="btn goBack"
                @click.prevent="goBackToProducts"
              >
                Torna indietro
              </button>
            </div>
          </div>
          <div
            v-if="errors.length || hasError"
            class="error section-padding-top"
          >
            <h2>
              Si è verificato un problema durante la generazione del documento
              da firmare digitalmente
            </h2>
            <button type="button" class="btn error" @click.prevent="reloadPage">
              Riprova
            </button>
          </div>
        </div>
      </div>
    </div>

    <div v-if="modalIsOpen" class="modal">
      <div v-if="esignResponse === 'ok' && showModal">
        <modal @close="closeModal">
          <template slot="content">
            <h3 class="modal-title active">Servizio attivato!</h3>
          </template>
        </modal>
      </div>
      <div v-if="esignResponse === 'ko' && showModal">
        <modal @close="closeModal">
          <template slot="content">
            <h3 class="modal-title not-active">Servizio non attivato!</h3>
            <p class="modal-content">
              Ci sono stati dei problemi durante la procedura di firma digitale
              su Namirial oppure hai deciso di non proseguire con il flusso
            </p>
          </template>
        </modal>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import TitleTextBox from '@components/title-text-box';
import Modal from '@components/modal';
import { required } from 'vuelidate/lib/validators';
import axios from '@helpers/axios';
export default {
  name: 'Product',
  components: { TitleTextBox, Modal },
  props: {
    slug: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      checked: null,
      secondChecked: null,
      timer: null,
      esignResponse: null,
      showModal: true,
      hasError: false,
    };
  },
  validations() {
    return {
      checked: {
        required,
        mustBeChecked: (val) => !!val,
      },
      secondChecked: {
        required,
        mustBeChecked: (val) => !!val,
      }
    };
  },
  computed: {
    ...mapState('products', ['products', 'product', 'errors']),
    modalIsOpen() {
      return this.esignResponse ? true : false;
    },
    thereIsAnActivatedProduct() {
      return !!this.products.find((p) => p.activated);
    },
    disabled() {
      return (
        this.product.activated ||
        this.esignResponse === 'ok' ||
        this.thereIsAnActivatedProduct
      );
    },
  },
  mounted() {
    this.$store.dispatch('products/setProductDetail', this.slug);
    this.$store.dispatch('setLoading', false);
    this.$store.dispatch('products/setProductsInitData').then(() => {
      this.$store.dispatch('setLoading', false);
    });
    this.esignResponse = this.$route.params.response;
    (() => {
      let doCheck = () => {
        if (this.product) {
          try {
            if (this.product.precontractual_accept) {
              this.checked = true;
              if(this.product.second_confirm_label) {
                this.secondChecked = true;
              }
            }
          } catch (e) {
            console.warning('Error Product');
          }
        } else {
          setTimeout(doCheck, 100);
        }
      };
      doCheck();
    })();
  },
  beforeDestroy() {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
  },
  methods: {
    downloadPdf(pdfUrl) {
      window.open(pdfUrl, '_blank');
    },
    closeModal() {
      this.showModal = false;
    },
    esignUrlPolling() {
      (() => {
        let doPolling = () => {
          // console.log('Faccio la chiamata di polling')
          axios
            .get(`/api/v1/sign-link/${this.product.slug}/`)
            .then((response) => {
              try {
                window.location.href = response.data.sign_link;
              } catch (e) {
                console.log(e); //print stacktrace
                console.error('Cannot read sign link url from response');
              }
            })
            .catch((e) => {
              if (e?.response?.status) {
                if (e.response.status === 404) {
                  setTimeout(doPolling, 850);
                } else if (e.response.status === 500) {
                  this.$store.dispatch('setLoading', false);
                  console.warn('Error 500');
                  this.hasError = true;
                } else {
                  console.warn('Unknown error');
                  this.$store.dispatch('setLoading', false);
                  this.hasError = true;
                }
              } else {
                console.warn('Unknown error');
                console.warn(e);
                this.hasError = true;
              }
            });
        };
        doPolling();
      })();
    },
    goBackToProducts() {
      this.$store.commit('products/RESET_PRODUCT_DETAIL', null);
      this.$store.commit('products/RESET_PRODUCTS', null);
      this.$router.push({
        name: 'products',
        query: { code: this.$route.query.code },
      });
    },
    async activateService() {
      if (this.disabled) {
        return;
      }
      //restituisce 403 nel caso in cui sia già stata attivata
      //alla 200 non dovrà essere fatto subito redirect, perchè bisogna prendere l'url dall'API di Polling
      try {
        if ((this.checked && !this.product.second_confirm_label) || (this.checked && this.secondChecked && this.product.second_confirm_label)) {
          this.$store.dispatch('setLoading', true);
          let result = await axios.put(
            `/api/v1/products/${this.product.slug}/`,
            {
              accept: true,
            }
          );
          if (result.status === 200) {
            // console.log('chiamo esignUrlPolling in activateService')
            this.esignUrlPolling();
          }
        } else {
          this.$v.checked.$touch();
          if(this.product.second_confirm_label){
            this.$v.secondChecked.$touch();
          }
        }
      } catch (e) {
        if (e?.response?.status) {
          console.log('ERRORE', e.response.status);
          this.hasError = true;
        } else {
          console.warn('Unknown error');
          console.warn(e);
          this.hasError = true;
        }
      }
    },
    reloadPage() {
      this.hasError = false;
      this.$store
        .dispatch('products/setProductDetail', this.product.slug)
        .then(() => {
          this.$store.dispatch('setLoading', false);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~$scss/_variables';
@import '~bootstrap/scss/mixins/breakpoints';

.Product {
  .titleProduct {
    font-size: 32px;
    color: $color-light-blue;

    @include media-breakpoint-up(md) {
      padding: 10px 0 0px 0;
      margin: 0;
      font-size: 50px;
    }
  }

  .description {
    margin-top: 25px;
  }

  .precontractualLink {
    display: block;
    margin-top: 50px;
    text-decoration: underline;
  }

  .esignLabel {
    margin-top: 50px;
    line-height: 25px;
  }

  .btn {
    border: 0;
    border-radius: 4px;
    padding: 15px 32px;
    margin-top: 30px;
    cursor: pointer;
    color: #fff;
    font-size: 19px;
    width: 100%;

    &.pdfBtn {
      background: #eb1274;
      transition: all 0.2s linear;

      &:hover {
        background: #e10568;
      }

      @include media-breakpoint-up(md) {
        width: 50%;
      }
      @include media-breakpoint-up(lg) {
        width: 30%;
      }
    }

    &.activate {
      background: $color-green;

      &:hover {
        background: #6fa43a;
      }

      &:disabled {
        cursor: default !important;
        background: #d0d2d6;
      }
    }

    &.goBack {
      background: $color-blue;

      &:hover {
        background: $color-blue-hover;
      }
    }

    &.error {
      background: #eb1274;
      width: auto;
    }
  }

  .checkbox {
    margin-top: 50px;
    position: relative;
    cursor: pointer;
    display: block;

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
    }

    /*custom checkbox */
    .checkbox-custom {
      position: absolute;
      height: 25px;
      width: 25px;
      border: 2px solid $color-grey;
      border-radius: 4px;

      &::after {
        content: '';
        position: absolute;
        opacity: 0;
      }

      &.error {
        border: 2px solid $color-error;
      }
    }

    input:checked ~ .checkbox-custom,
    .checked {
      background-color: $color-blue;
      border: 0;
    }

    input:checked ~ .checkbox-custom:after,
    .checked:after {
      opacity: 1;
    }

    .checkbox-custom:after {
      left: 10px;
      top: 7px;
      width: 5px;
      height: 10px;
      border: solid $color-white;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }

    .label {
      padding: 6px 0 0 30px;
      display: block;
      font-size: 12px;
    }

    .error {
      display: block;
      color: $color-error;
      font-size: 12px;
      padding-top: 10px;
    }
  }

  .errorWrapper {
    padding-top: 40px;
  }

  .modal {
    .modal-title {
      margin: 0;
      text-align: center;
      font-size: 36px;

      &.active {
        color: $color-green;
      }

      &.not-active {
        color: $color-error;
      }
    }

    .modal-content {
      margin-top: 20px;
      text-align: center;
      font-size: 18px;
      line-height: 24px;
      font-weight: $fh-thin;

      @include media-breakpoint-up(md) {
        font-size: 20px;
        line-height: 30px;
      }
    }
  }
}
</style>
