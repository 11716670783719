import * as types from '../mutation_types';

export default {
  [types.SET_PRODUCTS_INIT_DATA](state, payload) {
    state.products = payload
  },
  [types.RESET_PRODUCTS](state, payload) {
    state.products = payload
  },
  [types.SET_PRODUCT_DETAIL](state, payload) {
    state.product = {...payload}
  },
  [types.RESET_PRODUCT_DETAIL](state,payload) {
    state.product = payload;
  },
  [types.SET_PRODUCTS_API_ERRORS](state, payload) {
    state.errors.push(payload)
  },
};
