<template>
  <header class="HeaderCmp">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <img
            v-if="$context.siteCode === $context.solInsuranceSiteCode"
            src="../../img/logo-stellantis-financial-services.png"
            alt="logo"
          >
          <img
            v-else-if="$context.siteCode === $context.insuranceRentingSiteCode"
            src="../../img/logo-stellantis-renting.png"
            alt="logo"
          >
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: 'HeaderCmp',
  props: {

  }
}
</script>


<style lang="scss" scoped>
@import "~$scss/_variables";
@import "~bootstrap/scss/mixins/breakpoints";
.HeaderCmp{
  background-color: #243782;
  width: 100%;
}
</style>
