<template>
  <div class="Unauthorized">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <title-text-box>
            <template slot="content">
              <p>Non hai l'autorizzazione per accedere a questa pagina</p>
            </template>
          </title-text-box>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TitleTextBox from '@components/title-text-box'
export default {
  name:'Unauthorized',
  components:{TitleTextBox},
  data(){
    return{

    }
  },
  mounted(){
    this.$store.dispatch("setLoading", false);
  }
}
</script>

<style>

</style>
