<template>
  <div class="col-12 col-md-6 col-xl-4 my-3">
    <div class="ProductListItem">
      <h3 class="title">
        {{ product.name }}
      </h3>
      <div class="contentWrapper">
        <div
          v-if="product.abstract"
          class="description"
          v-html="product.abstract"
        />
        <div class="totalCost">
          {{ totalCost }}
          <span class="small">per mese</span>
        </div>
        <div class="ctaWrapper">
          <div v-if="!product.activated" class="cta">
            <button
              :class="['btnSelect', { disabled: dataForceDisabled }]"
              type="button"
              @click.prevent="onSelect"
            >
              Scopri di più
            </button>
          </div>
          <div v-else class="isActiveText">
            <svg width="20" height="20" class="icon">
              <use href="#icon-check" fill="currentColor" />
            </svg>
            <span>
              Complimenti! Servizio attivato, hai ricevuto una mail con in
              allegato l’accordo firmato.
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProductListItem',
  props: {
    product: {
      type: Object,
      required: true,
    },
    dataForceDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      clicked: false,
    };
  },
  computed: {
    totalCost() {
      return `${this.product.total_cost} €`;
    },
  },
  methods: {
    onSelect() {
      if (!this.dataForceDisabled && !this.clicked) {
        this.clicked = true;
        this.$emit('select-product');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~$scss/_variables';
@import '~bootstrap/scss/mixins/breakpoints';

.ProductListItem {
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.098);
  margin-top: 10px;
  background-color: #f0f3f6;
  border-radius: 4px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include media-breakpoint-up(sm) {
    margin-top: 20px;
  }

  .title {
    color: $color-light-blue;
    font-size: 28px;
    font-weight: $fh-bold;
    text-align: center;
    padding: 30px 5px 0;
    overflow: hidden;

    @include media-breakpoint-up(lg) {
      font-size: 30px;
      padding-top: 40px;
    }
  }

  .contentWrapper {
    @include media-breakpoint-up(sm) {
      padding: 0 20px;
    }

    @include media-breakpoint-up(lg) {
      padding: 0 40px;
    }

    .description {
      padding: 20px 0;
      color: #4d4d4d;
      line-height: 24px;
      flex-grow: 1;
    }

    .totalCost {
      padding-top: 30px;
      text-align: center;
      color: $color-light-blue;
      font-size: 38px;
      font-weight: $fh-bold;

      .small {
        display: block;
        font-size: 16px;
        font-weight: $fh-normal;
      }
    }
  }

  .ctaWrapper {
    text-align: center;
    padding: 50px 0;

    .btnSelect {
      border: 0;
      padding: 14px;
      border-radius: 4px;
      background: $color-blue;
      color: #fff;
      width: 100%;
      transition: all 0.2s;
      cursor: pointer !important;

      &:hover {
        background: $color-blue-hover;
      }

      &.disabled {
        background: $color-grey;
        cursor: default !important;
      }
    }

    .isActiveText {
      padding-top: 14px;
      line-height: 35px;

      .icon {
        margin-right: 10px;
        color: $color-green;
      }
    }
  }
}
</style>
