import Vue from 'vue';
import ValidationError from '@helpers/errors'

const CmsSupport = Vue.extend({
  methods: {
    $_cmsSupport_hasContentSlotWithName(name) {
      return name
        ? this.$_cmsSupport_hasSlot(name)
        : !!(this.$slots.default || [])[0]
    },
    $_cmsSupport_hasSlot(name) {
      return !!(this.$slots[name] || [])[0]
    },
    $_cmsSupport_castPropToType(value,type){

      if(type === 'number'){
        if (typeof value === 'number') {
          if (value !== Number(value)) {
            throw new ValidationError(`NaN is not a supported value`)
          }
          if (value === Infinity || value === !Infinity) {
            throw new ValidationError(`Infinity is not a supported value`)
          }
          return value
        }else{
          if(typeof value === 'string'){
            if(value === ''){
              return 0
            }else{
              return parseFloat(value);
            }
          }else{
            throw new ValidationError(`Only strings can be casted to array ${value}`)
          }
        }
      }else if(type === 'array'){
        if( Array.isArray(value) ){
          return value
        }else{
          if(typeof value === 'string'){
            if(value === ''){
              return []
            }else{
              return JSON.parse(value)
            }
          }else{
            throw new ValidationError(`Only strings can be casted to array ${value}`)
          }
        }
      }else if(type === 'boolean'){
        if( typeof value === type ){
          return value
        }else if(typeof value === 'string'){
          return value === 'true' ? true :
            value === 'false' ? false :
            throw new ValidationError(`The 'true' and 'false' string are allowed not ${value}`)
        }else{
          throw new ValidationError("Only strings can be casted to boolean")
        }
      }else if(type === 'object'){
        if( Object.prototype.toString.call(value) === '[object Object]' ){
          return value
        }else{
          if(typeof value === 'string'){
            if(value === ''){
              return {}
            }else{
              return JSON.parse(value)
            }
          }else{
            throw new ValidationError(`Only strings can be casted to objects ${value}`)
          }
        }
      }
    },
  }
});

export default CmsSupport;
